<template>
  <div>
    <!-- this form use for role table,user group table,group table, tag table , status table , project type -->
    <validation-observer ref="projectScopeObserver">
      <b-sidebar
        id="addProjectScope"
        ref="projectScopeForm"
        bg-variant="white"
        right
        backdrop
        shadow
        class="sidebar-form"
        @hidden="sidebarToggle"
      >
        <h3 class="form-title">
          {{ id ? "Edit" : "Add" }} Project Scope
        </h3>
        <div class="line user-underline" />
        <!--Project Scope name start-->
        <b-form-group
          label="Name"
          label-for="name"
          class="required-form-filed"
        >
          <validation-provider
            #default="{ errors }"
            name="Name"
            rules="required|min:3|max:30"
          >
            <b-form-input
              id="name"
              v-model="name"
              :state="errors.length > 0 ? false : null"
              placeholder="Project Scope Name"
              name="name"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <!--Project Scope name end-->

        <!--Project Scope name start-->
        <b-form-group
          label="Team color"
          label-for="scopeColor"
          class="required-form-filed"
        >
          <validation-provider
            #default="{ errors }"
            name="Color"
            rules="required"
          >
            <b-input-group class="color-picker-input">
              <b-form-input
                id="scopeColor"
                v-model="scopeColor"
                type="text"
                :state="errors.length > 0 ? false : null"
                name="scopeColor"
              />
              <b-input-group-append is-text>
                <input
                  v-model="scopeColor"
                  type="color"
                  name="scopecolor"
                  class="color-picker"
                >
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <!--Project Scope name end-->

        <template #footer>
          <!--form footer-->
          <div class="form-button d-flex my-2">
            <button
              class="btn side-bar-btn"
              :disabled="btnLoader"
              @click.prevent="validationForm"
            >
              <b-spinner
                v-if="btnLoader"
                small
                class="spinner"
              />
              {{ id ? "Update" : "Save" }}
            </button>
            <button
              class="btn side-bar-btn"
              @click="clearData"
            >
              Clear
            </button>
          </div>
        </template>
      </b-sidebar>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  name: 'ProjectTypeFrom',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      name: null,
      id: null,
      scopeColor: null,
      btnLoader: false,
    }
  },

  methods: {
    /**
     *  clear from detail
     */
    sidebarToggle() {
      this.id = null
      this.name = null
      this.scopeColor = null
      this.$refs.projectScopeObserver.reset()
    },

    /**
     * check the form validation
     *
     * @returns if success true then call saveClientDtl Method
     *
     */

    validationForm() {
      this.$refs.projectScopeObserver.validate().then(success => {
        if (success) {
          this.saveProjectScopeDtl()
        }
      })
    },

    /**
     * Add & Update the projectScopeForm details & call projectScope listing in project-scope-table
     * @emits projectScopeList
     * @returns projectScope detail add & update
     */
    async saveProjectScopeDtl() {
      this.btnLoader = true
      const color = this.scopeColor.substring(1)
      const input = {
        name: this.name,
        color,
      }
      let response = null
      if (this.id) {
        response = await this.getHTTPPutResponse(
          `admin/master/project-scope/update/${this.id}`,
          input,
          true,
        )
      } else {
        response = await this.getHTTPPostResponse(
          'admin/master/project-scope/create',
          input,
          true,
        )
      }

      if (response && response.status === 200) {
        this.$root.$emit('bv::toggle::collapse', 'addProjectScope')
        this.$emit('projectScopeList', 1)
        setTimeout(() => {
          this.btnLoader = false
        }, 200)
      }
      this.btnLoader = false
    },

    /**
     *  Clear form details
     */
    clearData() {
      this.name = null
      this.scopeColor = null
      this.$refs.projectScopeObserver.reset()
    },
  },
}
</script>
<style lang="scss" scoped>
.color-picker-input {
  .color-picker {
    height: 36px;
    width: 60px;
    border: transparent;
    background: transparent;
  }
  .input-group-text {
    padding: 0;
  }
  .form-control {
    border-right: none;
  }
}
</style>
